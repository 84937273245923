h3 {
    font-weight: 300;
}

::-webkit-scrollbar {
    background: transparent;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 100px;
}

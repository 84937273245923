h1, h2, h3, h4, h5, h6 {
    margin-top: 0px;
    margin-bottom: 0px;
}

h1 {
    font-size: 22px;
}

h2 {
    font-size: 18px;
}
@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
@import '~mapbox-gl/dist/mapbox-gl.css';
@import '/src/styles/blueprint-overrides.less';
@import '/src/styles/frame-io.less'; // variables to override above
@import '/src/styles/ant-overrides.less';
@import '/src/styles/algolia-overrides.less';
@import '/src/styles/mapbox-overrides.less';
@import '/src/styles/react-mosaic.less';
@import '/src/styles/constellation.less';

.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}
  

.site-layout .site-layout-background {
    background: #fff;
}

[data-theme="dark"] .site-layout .site-layout-background {
    background: #141414;
}

.mapboxgl-ctrl-attrib {
    display: none !important;
}

.mapboxgl-canvas-container {
    display: grid !important;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}
@font-size-default: 12px;
@font-size-bigger: 13px;

@gap-less: 6px;

@padding-less: 6px;
@padding-default: 8px;
@padding-more: 10px;

@border-radius-least: 4px;
@border-radius-less: 6px;
@border-radius-default: 8px;
@border-radius-more: 10px;
@border-radius-most: 12px;
@border-radius-button: 5px;

// layout
.ant-layout-sider {
    z-index: 1;
    flex: 0 0 240px !important;
    max-width: 240px !important;
    min-width: 240px !important;
    width: 240px !important;
}

// menu
.ant-menu-light {
    background: none;
    color: rgb(50, 55, 69);
}

.ant-menu-item {
    border-radius: 6px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 6px;
    margin-right: 6px;
    min-height: 36px !important;
}

.ant-menu-light .ant-menu-item.ant-menu-item-danger {
    color: rgb(50, 55, 69);
}

.ant-menu-light .ant-menu-item.ant-menu-item-danger:hover {
    background: rgb(255, 199, 210) !important;
    color: rgb(224, 71, 95) !important;
}

.ant-menu-light .ant-menu-item-selected.ant-menu-item-danger {
    background: rgb(255, 199, 210) !important;
    background-color: rgb(255, 199, 210) !important;
    color: rgb(224, 71, 95) !important;
}

.ant-menu-light .ant-menu-item-active {
    background-color: rgb(227, 230, 236) !important;
}

.ant-menu-item:hover {
    background-color: rgb(227, 230, 236) !important;
}

.ant-menu-submenu-title:hover {
    background-color: rgb(227, 230, 236) !important;
}

.ant-menu-dark .ant-menu-item-selected {
    background-color: @menu-item-active-bg !important;
}

.ant-menu-submenu-title {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.ant-menu-item-group-title {
    font-size: @font-size-default;
    font-weight: 600;
}

// button
.ant-btn {
    justify-content: center;
    background: none rgb(50, 55, 69);
    border: 0px;
    appearance: none;
    border-radius: @border-radius-button;
    box-sizing: border-box;
    white-space: nowrap;
    transition: background-color 0.2s ease 0s, color 0.2s ease 0s, box-shadow 0.2s ease 0s, opacity 0.2ms ease 0s;
    line-height: 1;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    align-items: center;
    gap: @gap-less;
    color: rgb(255, 255, 255);
    box-shadow: transparent 0px 0px inset;
    padding-left:  @padding-more;
    padding-right:  @padding-more;
    font-size:  @font-size-default;
    &:hover {
        color: unset;
        background-color: rgb(56, 61, 74);
    }
    &:active:not(:disabled) {
        color: unset;
        background-color: rgb(45, 49, 62);
    }
    &:focus:not(:active):not(:hover) {
        color: unset;
        background: none rgb(50, 55, 69);
        outline: none;
        box-shadow: rgb(83 175 255) 0px 0px 0px 1px inset, rgb(83 175 255) 0px 0px 4px 1px;
    }
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
    margin-left: 0px;
}

.ant-btn-default {
    background: none rgb(50, 55, 69);
    color: rgb(255, 255, 255);
    &:hover {
        color: unset;
        background-color: rgb(56, 61, 74);
    }
    &:active:not(:disabled) {
        color: unset;
        background-color: rgb(45, 49, 62);
    }
    &:focus:not(:active):not(:hover) {
        color: unset;
        background: none rgb(50, 55, 69);
        outline: none;
        box-shadow: rgb(83 175 255) 0px 0px 0px 1px inset, rgb(83 175 255) 0px 0px 4px 1px;
    }
}

.ant-row.ant-form-item .ant-btn {
    padding: @padding-default;
    height: auto;
    padding: @padding-default;
}

.ant-btn-primary {
    background: none;
    background-color: @primary-color !important;
}

.ant-btn-ghost {
    background: none;
    background-color: transparent !important;
    border: 1px solid rgba(255, 255, 255, 0.25);
    color: rgba(255, 255, 255, 0.85);
}

.ant-btn-icon-only {
    background: none rgb(50, 55, 69);
    border: 0px;
    padding: 0px;
    appearance: none;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: @font-size-default;
    font-weight: bold;
    letter-spacing: normal;
    font-stretch: normal;
    border-radius: 50%;
    box-sizing: border-box;
    white-space: nowrap;
    transition: background-color 0.2s ease 0s, color 0.2s ease 0s, box-shadow 0.2s ease 0s, opacity 0.2ms ease 0s;
    line-height: 1;
    height: 32px;
    width: 32px;
    color: rgb(255, 255, 255);
    box-shadow: transparent 0px 0px inset;
}

.ant-btn > .anticon {
    line-height: 0 !important;
}

.ant-btn-sm {
    border-radius: 14px !important;
}

.ant-btn.ant-btn-dangerous {
    background-color: @error-color;
    background: @error-color;
    &:hover {
        background-color: lighten(@error-color, 10) !important;
    }
    &:focus {
        background-color: @error-color !important;
    }
}

// input
input {
    &:focus {
        outline: none !important;
        border: none !important;
        background-color: rgb(255, 255, 255) !important;
        color: rgb(43, 47, 59) !important;
        &::placeholder {
            color: rgb(129, 139, 156);
        }
    }
}

.ant-input {
    font-size: @font-size-default;
    padding-top: @padding-default;
    padding-bottom: @padding-default;
    padding-left: @padding-more;
}

.ant-input-group-addon button {
    border: none !important;
}

.ant-input-wrapper {
    background-color: rgb(32, 34, 43);
    border-radius: 6px;
}

.ant-skeleton-loading-constellation {
    animation: skeleton-animation 1.4s ease infinite;
    opacity: 0.3;
}

.ant-skeleton-loading-constellation:nth-child(2) {
    animation-delay: 50ms;
}

.ant-skeleton-loading-constellation:nth-child(2) {
    animation-delay: 100ms;
}

@keyframes skeleton-animation {
    0% {
        opacity: 0.3
    }
    50% {
        opacity: 0.25
    }
    100% {
        opacity: 0.3
    }
}

.ant-row.ant-form-item {
    margin-bottom: @padding-more;
}

.ant-message-notice {
    margin-bottom: 0px;
}

.ant-menu.ant-menu-vertical {
    border-right: none;
}

.ant-select .ant-select-selector {
    padding-top: 4px;
    padding-bottom: 4px;
    min-height: 36px;
    border-radius: 6px !important;
}

.ant-select .ant-select-selection-overflow {
    row-gap: 4px;
}

.ant-select .ant-select-selector .ant-select-selection-item {
    line-height: 36px;
    font-size: @font-size-default;
}

.ant-select-item-option-content {
    font-size: @font-size-default;
}

.ant-picker {
    border-radius: 6px !important;
}

.ant-menu-item {
    height: auto !important;
}

.ant-popover-arrow {
    //display: none;
}

.ant-popover-inner {
    border-radius: 6px;
}

.ant-popover-inner-content {
    padding: @padding-less;
}

.ant-modal-content {
    background-color: rgb(15, 15, 15);
    border: 1px solid rgb(55, 55, 55);
    border-radius: 8px;
}

.ant-modal-header {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom: none;
    padding-bottom: 0px;
}

.ant-modal-footer {
    padding-bottom: 18px;
    padding-top: 0px;
    border-top: none;
    padding-left: 24px;
    padding-right: 24px;
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
}

.ant-breadcrumb {
    font-size:  @font-size-default;
}

.ant-modal-confirm-btns {
    display: grid;
    grid-auto-flow: column;
}

.ant-message {
    font-size: @font-size-default;
}

.ant-form-item-label > label {
    font-size: @font-size-bigger;
    font-weight: 500;
}

.ant-breadcrumb-link a:hover {
    color: rgba(255, 255, 255, 0.85);
}

.ant-select .ant-select-selector .ant-select-selection-item {
    line-height: unset;
}

.ant-select .ant-selection-item {
    background-color: rgb(48, 50, 69);
}

.ant-tag {
    border-radius: 4px;
}

.ant-tag i {
    margin-right: 7px;
}

.ant-select-selection-item {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}

.ant-select-selection-search-input {
    font-size: 13px;
}

.ant-form.ant-form-vertical {
    display: grid;
    gap: 10px;
}

.ant-row.ant-form-item {
    margin-bottom: 0px;
}

input.ant-select-selection-search-input {
    color: rgba(255, 255, 255, 0.85) !important;
    background-color: transparent !important;
}

.ant-tooltip-inner {
    font-size: 12px;
    background-color: #5e5e83;
    text-align: center;
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.85);
}

.ant-modal-confirm-body {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
}

.ant-select-selection-placeholder {
    font-size: 12px;
}

p {
    margin-bottom: 0.5em;
}

.ant-alert {
    border-radius: 8px;
    padding-left: 18px;
    padding-right: 18px;
}

.ant-alert-with-description .ant-alert-description {
    font-size: 12px;
    line-height: 1.2rem;
}

.ant-alert-info {
    
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
    opacity: 0.5;
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled {
    opacity: 0.5;
}